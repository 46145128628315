import Vue from "vue";

/*TITLE*/
document.title = "Almenar de Guadaíra | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Almenar de Guadaíra";
Vue.prototype.$subtitle = "¿Quieres vivir en una de las mejores zonas de Alcalá de Guadaíra en un exclusivo chalet con diseño y acabados de lujo? Ahora es posible";

/*INTRO*/
Vue.prototype.$promoter = "Almenar de Guadaíra";
Vue.prototype.$introSubtitle = "¿Quieres vivir en una de las mejores zonas de Alcalá de Guadaíra en un exclusivo chalet con diseño y acabados de lujo? Ahora es posible";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-opc.-1-forest-arce-22x90--20230112110122.jpg";
Vue.prototype.$image_kitchen = "cocina-opc.-1-pavimento-forest-arce-frentes-blanco-nubol-encimera-krion-snow-white--20230112110132.jpg";
Vue.prototype.$image_bath1 = "banop-opc.-1-pavimento-forest-arce-revestimiento-base-etherna-white-nature--20230112110147.jpg";
Vue.prototype.$image_bath2 = "banos-opc.-1-pavimento-forest-arce-revestimiento-base-sion-beige-nature--20230112110154.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-opc.-1-forest-arce-22x90--20230112110122.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-opc.-1-pavimento-forest-arce-frentes-blanco-nubol-encimera-krion-snow-white--20230112110132.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-opc.-1-pavimento-forest-arce-revestimiento-base-etherna-white-nature--20230112110147.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banos-opc.-1-pavimento-forest-arce-revestimiento-base-sion-beige-nature--20230112110154.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/VIRGEN_DEL_AGUILA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/Ml1yL5GHTpM";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20la%20habana%20alcalá%20de%20guadaíra%2041500%20sevilla",
    text: "Calle la Habana, Alcalá de Guadaíra, 41500. Sevilla",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20la%20habana%20alcalá%20de%20guadaíra%2041500%20sevilla",
    text: "Calle la Habana, Alcalá de Guadaíra, 41500. Sevilla",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:955300313",
    text: "955300313",
  },
  {
    icon: "mdi-email",
    link: "mailto:ade@grupoade.es",
    text: "ade@grupoade.es",
  },
];
